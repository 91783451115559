import classNames from "classnames";
import { format } from "date-fns/format";
import { ro } from "date-fns/locale";
import {
  CalendarFoldIcon,
  CalendarIcon,
  ClockIcon,
  MapPinIcon,
  PenIcon,
  SquareArrowOutUpRightIcon,
  StoreIcon,
  Trash2Icon,
  UserCircleIcon,
} from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "../../common/AlertDialog";
import { Button, getButtonClass } from "../../common/Button";
import { Card } from "../../common/Card";
import TipTapEditor from "../../common/Editor/TipTapEditor";
import { OnLink } from "../../common/OnLink";
import { OnStatusBadge, type OnStatus } from "../../common/StatusBadge";
import { Text } from "../../common/Text";
import { LogLevel, UserEventId, webLogger } from "../../logging/webLogger";
import { useIsUserAdmin } from "../../user/UserContext";
import { camelCase } from "../../utils/camelCase";
import { useWindowSize, WindowSize } from "../../utils/useWindowSize";
import { useEvent, useEventsContext } from "./EventsContext";
import { MediaView } from "./MediaView";
import type { OnEvent } from "./OnEvent";

export function EventPage() {
  const { eventId } = useParams();

  const isUserAdmin = useIsUserAdmin();
  const navigate = useNavigate();
  const { currentEvent, loadingState } = useEvent(eventId);
  const windowSize = useWindowSize();

  useEffect(() => {
    if (loadingState === "ready" && currentEvent == null) {
      navigate("/");
    }
  }, [currentEvent, loadingState, navigate]);

  const hasLogged = useRef(false);
  useEffect(() => {
    if (!hasLogged.current) {
      hasLogged.current = true;
      webLogger.log(LogLevel.Info, "Highlight page loaded", {
        event: UserEventId.EVENT_PAGE_LOADED,
        eventId,
      });
    }
  }, [eventId]);

  if (loadingState === "loading") {
    return <div>Se incarca...</div>;
  }

  if (currentEvent == null) {
    navigate("/");
    return null;
  }

  const headerImages = currentEvent?.media ?? [];
  return (
    <div className="grid grid-cols-12 gap-2 py-4 mx-2 min-h-[calc(100vh-10rem)]">
      {isUserAdmin ? (
        <EditEventMenu
          eventId={currentEvent.id}
          status={currentEvent.status}
          horizontal={windowSize !== WindowSize.Large}
        />
      ) : null}
      <Card
        className={classNames(
          "flex flex-col gap-2 col-span-full lg:col-start-4 lg:col-span-6 border border-orange-200 rounded-md shadow shadow-orange-100 bg-white"
        )}
      >
        <MediaView media={headerImages} />
        <div className="flex flex-col gap-2 p-4">
          <div className="flex flex-col mb-2">
            <div className="flex flex-row gap-2 items-center">
              <CalendarFoldIcon className="w-6 h-6 shrink-0" />
              <h1 className="text-2xl font-semibold">
                {currentEvent?.name ?? "Placeholder name"}
              </h1>
            </div>
            <Text color="secondary">
              {currentEvent?.oneLine ?? "One line description of the event"}
            </Text>
          </div>
          <div className="flex flex-row justify-between flex-wrap mb-4 items-start">
            <div className="flex flex-col gap-2 sm:gap-1">
              <div className="flex flex-row gap-1 items-center flex-wrap">
                <Text emphasized color="secondary" className="w-24 shrink-0">
                  Dată
                </Text>
                <EventTimes
                  startDate={currentEvent.startDate}
                  endDate={currentEvent.endDate}
                />
              </div>
              <div className="flex flex-row gap-1 items-center flex-wrap">
                <Text emphasized color="secondary" className="w-24 shrink-0">
                  Locație
                </Text>

                {currentEvent.locationOnMap != null ? (
                  <OnLink
                    href={currentEvent.locationOnMap}
                    icon={<MapPinIcon className="w-4 h-4 shrink-0" />}
                    value={currentEvent?.address ?? "Address for this event"}
                    target="_blank"
                  />
                ) : (
                  <>
                    <MapPinIcon className="w-4 h-4 text-secondary shrink-0" />
                    <Text>
                      {currentEvent?.address ?? "Address for this event"}
                    </Text>
                  </>
                )}
              </div>
              {currentEvent?.organizer != null && (
                <div className="flex flex-row gap-1 items-center flex-wrap">
                  <Text emphasized color="secondary" className="w-24 shrink-0">
                    Organizator
                  </Text>
                  <Link to={`/iasi/organizatori/${currentEvent.organizer.id}`}>
                    <OnLink
                      icon={
                        currentEvent?.organizerType === "Entity" ? (
                          <StoreIcon className="w-4 h-4 shrink-0" />
                        ) : (
                          <UserCircleIcon className="w-4 h-4 shrink-0" />
                        )
                      }
                      value={currentEvent?.organizer?.name ?? "<organizator>"}
                    />
                  </Link>
                </div>
              )}
            </div>
            {(currentEvent?.links.length ?? 0) > 0 && (
              <div className="flex flex-col items-end border border-input bg-neutral-50/40 rounded-md px-4 py-2 ml-auto">
                <Text emphasized color="secondary">
                  Linkuri
                </Text>
                {currentEvent?.links.map((linkWithCategory, key) => {
                  const { category, url } = linkWithCategory;
                  let absoluteLink = url;
                  if (!absoluteLink.startsWith("http")) {
                    absoluteLink = `https://${absoluteLink}`;
                  }
                  return (
                    <OnLink
                      key={key}
                      href={absoluteLink}
                      value={category}
                      target="_blank"
                      rightIcon={
                        <SquareArrowOutUpRightIcon className="w-4 h-4" />
                      }
                    />
                  );
                })}
              </div>
            )}
          </div>
          {currentEvent == null ? (
            <div>Se pregateste descrierea...</div>
          ) : (
            <TipTapEditor
              editable={false}
              content={currentEvent?.description}
              placeholder="Evenimentul nu are nicio descriere"
            />
          )}
        </div>
      </Card>
    </div>
  );
}

function EventTimes({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate?: Date;
}) {
  const hasEndDate = endDate != null;
  const hasSpecificEndTime =
    endDate != null &&
    (endDate.getHours() != 23 ||
      endDate.getMinutes() != 59 ||
      endDate.getSeconds() != 59);

  const endDateIsSameDay =
    endDate != null &&
    startDate.getDate() === endDate.getDate() &&
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear();

  return (
    <div className="flex flex-col gap-1 flex-wrap">
      <div className="flex flex-row gap-x-2 gap-y-1 items-center flex-wrap">
        <EventDay date={startDate} />
        <div className="flex flex-row gap-1 items-center">
          <EventHours date={startDate} />
          {endDate != null && endDateIsSameDay && hasSpecificEndTime ? (
            <>
              <span>-</span>
              <EventHours date={endDate} />
            </>
          ) : null}
        </div>
      </div>
      {hasEndDate && endDate != null && !endDateIsSameDay ? (
        <div className="flex flex-row gap-2 items-center">
          <EventDay date={endDate} isEnd />
          {hasSpecificEndTime ? <EventHours date={endDate} /> : null}
        </div>
      ) : null}
    </div>
  );
}

function EventDay({ date, isEnd = false }: { date: Date; isEnd?: boolean }) {
  return (
    <div className="flex flex-row gap-1 items-center shrink-0">
      {isEnd ? (
        <CalendarFoldIcon className="w-4 h-4 text-secondary" />
      ) : (
        <CalendarIcon className="w-4 h-4 text-secondary" />
      )}
      <Text>
        {camelCase(
          format(date, "PPPP", {
            locale: ro,
          })
        )}
      </Text>
    </div>
  );
}

function EventHours({ date }: { date: Date }) {
  return (
    <div className="flex flex-row shrink-0 items-center gap-1">
      <ClockIcon className="w-4 h-4 text-secondary" />
      <Text emphasized>
        {format(date, "p", {
          locale: ro,
        })}
      </Text>
    </div>
  );
}

export function splitOnce(str: string, separator: string) {
  const index = str.indexOf(separator);
  if (index === -1) return [str];
  return [str.slice(0, index), str.slice(index + 1)];
}

type DeleteEventAlertDialogProps = {
  onConfirm: () => void;
};

function DeleteEventAlertDialog({ onConfirm }: DeleteEventAlertDialogProps) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  return (
    <AlertDialog open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <AlertDialogTrigger asChild>
        <Button
          icon={<Trash2Icon className="w-4 h-4" />}
          value="Sterge"
          size="small"
          variant="destructive"
          onClick={() => setIsDialogOpen(true)}
        />
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Sigur doresti sa stergi acest eveniment?
          </AlertDialogTitle>
          <AlertDialogDescription>
            Datele pierdute in urma stergerii nu vor putea fi recuperate.
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Continua completarea</AlertDialogCancel>
          <AlertDialogAction
            onClick={onConfirm}
            className={getButtonClass({ variant: "destructive" })}
          >
            Sterge
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

type EditEventMenuProps = {
  eventId: string;
  status: OnEvent["status"];
  horizontal?: boolean;
};

function EditEventMenu({ eventId, status, horizontal }: EditEventMenuProps) {
  const navigate = useNavigate();
  const { deleteEvent } = useEventsContext();
  return (
    <div
      className={classNames(
        "flex gap-2",
        horizontal
          ? "col-span-full lg:col-start-4 lg:col-span-6 justify-end"
          : "col-start-10 row-start-1 row-span-1 flex-col justify-start items-start"
      )}
    >
      <OnStatusBadge status={status as unknown as OnStatus} />
      <Button
        icon={<PenIcon className="w-4 h-4" />}
        value="Modifica"
        size="small"
        variant="outlineLight"
        onClick={() => navigate("/iasi/evenimente/" + eventId + "/modifica")}
      />
      <DeleteEventAlertDialog
        onConfirm={() => {
          deleteEvent(eventId!);
          navigate("/");
        }}
      />
    </div>
  );
}
